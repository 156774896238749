import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPlugin from "./material-dashboard";

// Plugins
import VueI18n from "vue-i18n";
import vnMessage from "./lang/vn.json";
import enMessage from "./lang/en.json";
import App from "./App.vue";
import VueApexCharts from "vue-apexcharts";
// router setup
import routes from "./routes/routes";
import VueCarousel from "vue-carousel";
import utils from "./utils";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import vmodal from "vue-js-modal";
import VueKonva from "vue-konva";
import VueLazyload from "vue-lazyload";
import DatePicker from "vue2-datepicker";
import $ from "jquery";
import {BootstrapVue, IconsPlugin} from "bootstrap-vue";
import VCalendar from "v-calendar";
import Vuetify from "vuetify";
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
import "vue2-datepicker/locale/vi";
import "vue2-datepicker/locale/en";
import VueSparkline from 'vue-sparklines';
import EventBus from "@/EventBus";
import VueFusionCharts from "vue-fusioncharts";
// import FusionCharts modules and resolve dependency
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import FusionWidgetsXT from "fusioncharts/fusioncharts.charts";
import Power from "fusioncharts/fusioncharts.powercharts";
import Widgets from 'fusioncharts/fusioncharts.widgets';
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

Vue.use(VueFusionCharts, FusionCharts, Charts, Power, FusionTheme, FusionWidgetsXT, Widgets);
Vue.use(VueI18n);
Vue.use(VueSparkline);
Vue.use(VueSweetalert2);
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(VueCarousel);
Vue.use(vmodal);
Vue.use(VueKonva);
Vue.use(VueLazyload);
Vue.use(DatePicker);
Vue.use($);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VCalendar);
Vue.use(Vuetify);

Vue.directive("click-outside", {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unbind: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
    }
});


if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
} else if (Notification.permission === "granted") {
    console.log("notification granted");
} else if (Notification.permission === "denied") {
    console.log(Notification.permission);
    Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
            console.log("Permission to receive notifications has been granted");
        }
    });
}

if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("firebase-messaging-sw.js").then(
        function (registration) {
            console.log("firebase-message-sw :ServiceWorker registration successful with scope: ", registration.scope);
        },
        function (err) {
            console.log("firebase-message-sw: ServiceWorker registration failed: ", err);
        }
    );
} else {
    console.warn("Service Worker not available");
}


Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false;
Vue.config.silent = true;



// axios.defaults.baseURL = "https://" + process.env.VUE_APP_BASE_API + "/api/v1/";
if (process.env.VUE_APP_BASE_ENV == "prod") {
    axios.defaults.baseURL = "https://" + process.env.VUE_APP_BASE_API_PROD + "/api/v1/";
} else if (process.env.VUE_APP_BASE_ENV == "staging") {
    axios.defaults.baseURL = "https://" + process.env.VUE_APP_BASE_API_STAGING + "/api/v1/";
} else {
    // axios.defaults.baseURL = "http://192.168.30.100:8082/api/v1/";
    axios.defaults.baseURL = "https://api.cxview.ai:8081/prod/api/v1/"; 
    // axios.defaults.baseURL = "https://api.cxview.ai:8081/api/v1/";
}
// axios.defaults.baseURL = "http://192.168.30.100:8082" + "/api/v1/";

let jwt = utils.get_token();
axios.defaults.headers.common["Authorization"] = jwt;

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        console.log("error: ", error);
        const {status} = error.response;
        if (status === 401 && !window.location.pathname.startsWith("/login")) {
            utils.logout("/login");
        }
        return Promise.reject(error);
    }
);

let lang = localStorage.getItem("lang");

if (lang == null) {
    lang = "en"
    localStorage.setItem('lang', "en")
    DatePicker.locale("en");
}

const messages = {
    vn: vnMessage,
    en: enMessage
};

let i18n = new VueI18n({
    locale: lang, // set locale
    messages,
    fallbackLocale: lang
});


// configure router
const router = new VueRouter({
    routes, // short for routes: routes
    mode: 'history',
    scrollBehavior: (to) => {
        if (to.hash) {
            return {selector: to.hash};
        } else {
            return {x: 0, y: 0};
        }
    },
    linkExactActiveClass: "nav-item active",
});

// global library setup

/* eslint-disable no-new */
new Vue({
    el: "#app",
    i18n,
    router,
    render: h => h(App),

});

EventBus.$on("changeLanguage", data => {
    localStorage.setItem("lang", data);
    lang = data;
    i18n.locale = data;
    location.reload();
});